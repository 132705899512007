import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App.js";
import ProtocolPage from "./components/ProtocolPage.js";
import LIMS from "./components/LIMS.js";
import Tools from "./components/Tools.js";
import ToolPage from "./components/ToolPage.js";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/index.js";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Auth0Provider
    domain="login.labkick.ai"
    clientId="wIR2ovSzlTWy30MYdyeKmsHsFnjcMMds"
    authorizationParams={{
      audience: "http://localhost:5000",
      redirect_uri: window.location.origin,
    }}
  >
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<ProtocolPage />} />
            <Route path="/project/:projectId" element={<ProtocolPage />} />
            <Route path="/lims" element={<LIMS />} />
            <Route path="/tools" element={<Tools />} />
            <Route path="/tools/:toolId" element={<Tools />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  </Auth0Provider>
);
