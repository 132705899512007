import React, { useState, useEffect, useCallback, useRef } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { useDispatch } from 'react-redux';
import { editProtocolData, uploadNotebookImage } from '../store/project.js';
import '../style/EditableNotebook.css';

function EditableNotebook({ content, fileId }) {
    const [value, setValue] = useState(content);
    const [isEditing, setIsEditing] = useState(false);
    const [showCopyNotification, setShowCopyNotification] = useState(false);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const editorRef = useRef(null);
    const selectionStartRef = useRef(null);
    const selectionEndRef = useRef(null);

    useEffect(() => {
        if (!isEditing) {
            // Update value when content changes and not editing
            setValue(content);
        }
    }, [content, isEditing]);

    const handleSave = async () => {
        try {
            await dispatch(
                editProtocolData({
                    fileId: fileId,
                    inputs: { content: value },
                })
            );
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving notebook:', error);
        }
    };

    const handleImageUpload = async (file) => {
        try {
            const editor = editorRef.current?.textarea;
            let start = 0;
            let end = 0;

            if (editor) {
                start = editor.selectionStart;
                end = editor.selectionEnd;
            }

            const imageUrl = await dispatch(uploadNotebookImage(file)).unwrap();
            const imageMarkdown = `![${file.name}](${imageUrl})`;
            const newValue = value.substring(0, start) + imageMarkdown + value.substring(end);

            setValue(newValue);

            // After setting new value, restore cursor position
            setTimeout(() => {
                if (editor) {
                    const cursorPosition = start + imageMarkdown.length;
                    editor.selectionStart = editor.selectionEnd = cursorPosition;
                    editor.focus();
                }
            }, 0);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handlePaste = useCallback(
        (event) => {
            const items = event.clipboardData?.items;
            if (!items) return;

            for (const item of items) {
                if (item.type.startsWith('image/')) {
                    event.preventDefault();
                    const file = item.getAsFile();
                    if (file) handleImageUpload(file);
                    break;
                }
            }
        },
        [handleImageUpload]
    );

    const handleDrop = useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            const files = event.dataTransfer.files;
            for (const file of files) {
                if (file.type.startsWith('image/')) {
                    handleImageUpload(file);
                    break;
                }
            }
        },
        [handleImageUpload]
    );

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const handleEditorChange = (val) => {
        setValue(val);
    };

    const handleEditorFocus = () => {
        const editor = editorRef.current?.textarea;
        if (editor) {
            selectionStartRef.current = editor.selectionStart;
            selectionEndRef.current = editor.selectionEnd;
        }
    };

    const handleEditorBlur = () => {
        const editor = editorRef.current?.textarea;
        if (editor) {
            selectionStartRef.current = editor.selectionStart;
            selectionEndRef.current = editor.selectionEnd;
        }
    };

    const textareaProps = {
        className: 'enb-editor-textarea',
        onFocus: handleEditorFocus,
        onBlur: handleEditorBlur,
    };

    const handleCopyContent = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setShowCopyNotification(true);
            setTimeout(() => {
                setShowCopyNotification(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy content:', error);
        }
    };

    if (isEditing) {
        return (
            <div
                className="enb-editable-notebook"
                data-color-mode="light"
                onPaste={handlePaste}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <div className="enb-notebook-toolbar">
                    <button
                        className="enb-notebook-button enb-save-button"
                        onClick={handleSave}
                        aria-label="Save changes"
                    >
                        Save
                    </button>
                    <button
                        className="enb-notebook-button enb-cancel-button"
                        onClick={() => {
                            setValue(content);
                            setIsEditing(false);
                        }}
                        aria-label="Cancel editing"
                    >
                        Cancel
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) handleImageUpload(file);
                            e.target.value = '';
                        }}
                    />
                    <button
                        className="enb-notebook-button enb-upload-button"
                        onClick={() => fileInputRef.current?.click()}
                        aria-label="Upload image"
                    >
                        Upload Image
                    </button>
                </div>
                <div className="enb-editor-container">
                    <MDEditor
                        value={value}
                        onChange={handleEditorChange}
                        preview="edit"
                        height={600}
                        className="enb-md-editor"
                        ref={editorRef}
                        textareaProps={textareaProps}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="enb-editable-notebook" data-color-mode="light">
            <div className="enb-notebook-toolbar">
                <div className="enb-copy-button-container">
                    <button
                        className="enb-copy-button"
                        onClick={handleCopyContent}
                        aria-label="Copy content"
                    >
                        <img src="/images/copy-csv.svg" alt="" aria-hidden="true" className="enb-copy-icon" />
                    </button>
                    {showCopyNotification && (
                        <div className="enb-copy-tooltip">
                            Content copied to clipboard
                        </div>
                    )}
                </div>
                <button
                    className="enb-notebook-button enb-edit-button"
                    onClick={() => setIsEditing(true)}
                    aria-label="Edit notebook"
                >
                    Edit
                </button>
            </div>
            <MDEditor.Markdown source={value} className="enb-md-preview" />
        </div>
    );
}

export default EditableNotebook;
