import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoNotificationsOutline, IoClose, IoArrowUndoOutline } from 'react-icons/io5';
import { fetchUserNotifications, updateNotifications, setNotifications, setPopupMessage } from '../store/user.js';
import { format, isToday } from 'date-fns';
import '../style/NotificationsDropdown.css';
import { useNavigate } from 'react-router-dom';
import { loadProjectFromNotification } from '../store/project.js';

// Format the timestamp
const formatNotificationTime = (timestamp) => {
    const date = new Date(timestamp);
    if (isToday(date)) {
        return format(date, 'h:mm a');
    }
    return format(date, 'MMM d, yyyy');
};

function NotificationsDropdown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [showDismissed, setShowDismissed] = useState(false);
    const dropdownRef = useRef(null);

    // Get notifications from Redux state
    const notifications = useSelector((state) => state.user.notifications_unread || []);
    const dismissedNotifications = useSelector((state) => state.user.notifications_dismissed || []);
    const isAuthenticated = useSelector((state) => state.user.status === 'succeeded');
    const userId = useSelector((state) => state.user.user_id);
    const token = useSelector((state) => state.user.access_token);

    // Calculate number of unread notifications (for badge)
    const unreadCount = notifications.length;
    // Calculate number of unseen notifications (for marking as seen)
    const unseenCount = notifications.filter(notif => !notif.seen).length;


    useEffect(() => {
        console.log(notifications);
    }, [notifications]);

    // Set up polling only if authenticated
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserNotifications());
            const interval = setInterval(() => {
                dispatch(fetchUserNotifications());
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [dispatch, isAuthenticated]);

    // Don't render anything if not authenticated
    if (!isAuthenticated) {
        return null;
    }

    const removeNotification = (id) => {
        const notificationToMove = notifications.find(notif => notif.id === id);
        if (notificationToMove) {
            // Move to dismissed list
            const updatedDismissed = [...dismissedNotifications, notificationToMove];
            const updatedUnread = notifications.filter(notif => notif.id !== id);
            
            // Update backend and Redux state
            dispatch(updateNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));

            // Immediately update local state
            dispatch(setNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));
        }
    };

    const restoreNotification = (id) => {
        const notificationToRestore = dismissedNotifications.find(notif => notif.id === id);
        if (notificationToRestore) {
            // Move back to unread list
            const updatedUnread = [...notifications, notificationToRestore];
            const updatedDismissed = dismissedNotifications.filter(notif => notif.id !== id);
            
            // Update backend and Redux state
            dispatch(updateNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));

            // Immediately update local state
            dispatch(setNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));

            // If no more dismissed notifications, switch back to unread view
            if (updatedDismissed.length === 0) {
                setShowDismissed(false);
            }
        }
    };

    const handleActionUrl = async (url, notificationId) => {
        // Extract project ID from URL
        const match = url.match(/\/project\/([a-zA-Z0-9-]+)/);
        if (match) {
            const projectId = match[1];
            try {
                await dispatch(loadProjectFromNotification(projectId)).unwrap();
                
                // Remove notification from either unread or dismissed list
                const updatedUnread = notifications.filter(notif => notif.id !== notificationId);
                const updatedDismissed = dismissedNotifications.filter(notif => notif.id !== notificationId);
                
                // Update backend and Redux state
                dispatch(updateNotifications({
                    unread: updatedUnread,
                    dismissed: updatedDismissed
                }));

                // Immediately update local state
                dispatch(setNotifications({
                    unread: updatedUnread,
                    dismissed: updatedDismissed
                }));
                
                navigate(url);
            } catch (error) {
                console.error('Error handling notification URL:', error);
                dispatch(setPopupMessage('Error loading project. Please try again.'));
                navigate('/');
            }
        } else {
            // For non-project URLs, just navigate and remove notification
            const updatedUnread = notifications.filter(notif => notif.id !== notificationId);
            const updatedDismissed = dismissedNotifications.filter(notif => notif.id !== notificationId);
            
            // Update backend and Redux state
            dispatch(updateNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));

            // Immediately update local state
            dispatch(setNotifications({
                unread: updatedUnread,
                dismissed: updatedDismissed
            }));
            
            navigate(url);
        }
        setIsOpen(false);
    };

    // Handle opening notifications
    const handleOpenNotifications = async () => {
        if (!isOpen) {
            try {
                // Mark all notifications as seen
                const updatedNotifications = notifications.map(notif => ({
                    ...notif,
                    seen: true
                }));
                
                // Update backend and Redux state
                await dispatch(updateNotifications({
                    unread: updatedNotifications,
                    dismissed: dismissedNotifications
                })).unwrap();
            } catch (error) {
                console.error('Error updating notifications seen status:', error);
            }
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="notifications-container" ref={dropdownRef}>
            {/* Always show bell, but only show count if there are unread notifications */}
            <button
                className="notifications-button"
                onClick={handleOpenNotifications}
            >
                <IoNotificationsOutline size={20} />
                {unreadCount > 0 && (
                    <span className="notifications-badge">{unreadCount}</span>
                )}
            </button>

            {isOpen && (
                <div className="notifications-dropdown">
                    <div className="notifications-header">
                        <h3>{showDismissed ? 'Dismissed' : 'Notifications'}</h3>
                        <button
                            className="notifications-close-all"
                            onClick={() => {
                                setIsOpen(false);
                                setShowDismissed(false);
                            }}
                        >
                            <IoClose size={20} />
                        </button>
                    </div>
                    <div className="notifications-list">
                        {showDismissed ? (
                            dismissedNotifications.length === 0 ? (
                                <div className="no-notifications">No dismissed notifications</div>
                            ) : (
                                dismissedNotifications.map(notification => (
                                    <div key={notification.id} className="notification-item">
                                        <div className="notification-content">
                                            <h4>{notification.title}</h4>
                                            <p>{notification.message}</p>
                                            <span className="notification-timestamp">
                                                {formatNotificationTime(notification.timestamp)}
                                            </span>
                                            <div className="notification-actions">
                                                <button
                                                    className="notification-open-button"
                                                    onClick={() => {
                                                        handleActionUrl(notification.actionUrl, notification.id);
                                                    }}
                                                >
                                                    Open
                                                </button>
                                                <button
                                                    className="notification-restore-button"
                                                    onClick={() => restoreNotification(notification.id)}
                                                >
                                                    <IoArrowUndoOutline size={16} />
                                                    Restore
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        ) : (
                            notifications.length === 0 ? (
                                <div className="no-notifications">No notifications</div>
                            ) : (
                                notifications.map(notification => (
                                    <div key={notification.id} className="notification-item">
                                        <div className="notification-content">
                                            <h4>{notification.title}</h4>
                                            <p>{notification.message}</p>
                                            <span className="notification-timestamp">
                                                {formatNotificationTime(notification.timestamp)}
                                            </span>
                                            <button
                                                className="notification-open-button"
                                                onClick={() => {
                                                    handleActionUrl(notification.actionUrl, notification.id);
                                                }}
                                            >
                                                Open
                                            </button>
                                        </div>
                                        <button
                                            className="notification-close-button"
                                            onClick={() => removeNotification(notification.id)}
                                        >
                                            <IoClose size={16} />
                                        </button>
                                    </div>
                                ))
                            )
                        )}
                    </div>
                    <div className="notifications-footer">
                        <button
                            className="notifications-toggle-dismissed"
                            onClick={() => setShowDismissed(!showDismissed)}
                        >
                            {showDismissed ? 'Show Current' : 'Show Dismissed'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NotificationsDropdown; 