import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UploadToTool from '../UploadToTool.js';
import { removeToolFile } from '../../store/project.js';
import './ToolPrimerDesign.css';
import { runPrimerDesign } from '../../api.js';

function ToolPrimerDesign({ tool }) {
    const selectedFiles = useSelector((state) => state.project.tools_file_ids);
    const dispatch = useDispatch();
    const [leftExtensions, setLeftExtensions] = useState('');
    const [rightExtensions, setRightExtensions] = useState('');
    const [goal, setGoal] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [primerData, setPrimerData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCopyNotification, setShowCopyNotification] = useState(false);
    const [error, setError] = useState(null);
    const token = useSelector((state) => state.user.access_token);

    const handleDesign = async () => {
        if (selectedFiles.length === 0) return;

        setIsLoading(true);
        setError(null);

        try {
            const fileIds = selectedFiles.map(file => file.id);
            const result = await runPrimerDesign(
                fileIds,
                leftExtensions,
                rightExtensions,
                goal,
                token
            );

            setPrimerData(result.primers || []);

            setOrderData(result.order_data || []);

            setSubmitted(true);

            selectedFiles.forEach(file => {
                dispatch(removeToolFile(file.id));
            });
        } catch (err) {
            setError(err.message || 'Failed to design primers');
            console.error('Error designing primers:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const getOrderCSV = () => {
        const headers = ['Primer Name', 'Price'];
        const rows = orderData.map(item => `${item.primer},${item.price}`);
        return [headers.join(','), ...rows].join('\n');
    };

    const handleCopyCSV = () => {
        navigator.clipboard.writeText(getOrderCSV());
        setShowCopyNotification(true);
        setTimeout(() => {
            setShowCopyNotification(false);
        }, 2000);
    };

    return (
        <div className="tool-primerdesign-container">
            <h1 className="tool-primerdesign-title">{tool.title}</h1>
            <p className="tool-primerdesign-description">{tool.description}</p>

            <div className="tool-primerdesign-upload">
                <UploadToTool />
            </div>

            <div className="tool-primerdesign-extensions">
                <div className="tool-primerdesign-extension-box">
                    <label htmlFor="left-ext">Left extensions (comma separated)</label>
                    <input
                        type="text"
                        id="left-ext"
                        value={leftExtensions}
                        onChange={(e) => setLeftExtensions(e.target.value)}
                        placeholder="e.g. ATGCGT, CGTAAA"
                    />
                </div>
                <div className="tool-primerdesign-extension-box">
                    <label htmlFor="right-ext">Right extensions (comma separated)</label>
                    <input
                        type="text"
                        id="right-ext"
                        value={rightExtensions}
                        onChange={(e) => setRightExtensions(e.target.value)}
                        placeholder="e.g. TTGACG, GGCCAA"
                    />
                </div>
            </div>

            <div className="tool-primerdesign-goal">
                <label htmlFor="goal">Goal or Instructions (optional)</label>
                <textarea
                    id="goal"
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    placeholder="Describe your design goals..."
                />
            </div>

            <button
                className="tool-primerdesign-button"
                onClick={handleDesign}
                disabled={selectedFiles.length === 0 || isLoading}
            >
                {isLoading ? (
                    <div className="toolprimerdesign-loading">
                        <div className="toolprimerdesign-spinner"></div>
                        <span>Designing Primers...</span>
                    </div>
                ) : (
                    'Design Primers'
                )}
            </button>

            {submitted && (
                <div className="tool-primerdesign-output">
                    <h3>Primer Table</h3>
                    <table className="tool-primerdesign-table">
                        <thead>
                            <tr>
                                <th>Gene Name</th>
                                <th>Primer Name</th>
                                <th>Sequence</th>
                                <th>Length</th>
                                <th>Tm</th>
                                <th>GC%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {primerData.map((primer, i) => (
                                <tr key={i}>
                                    <td>{primer.gene_name}</td>
                                    <td>{primer.name}</td>
                                    <td>{primer.sequence}</td>
                                    <td>{primer.length}</td>
                                    <td>{primer.tm}°C</td>
                                    <td>{primer.gc_content}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="toolprimerdesign-order">
                        <div className="toolprimerdesign-order-header">
                            <h3>Order Form</h3>
                            <div className="copy-button-container">
                                <button
                                    className="toolprimerdesign-copy-button"
                                    onClick={handleCopyCSV}
                                    title="Copy as CSV"
                                >
                                    <img src="/images/copy-csv.svg" alt="Copy CSV" />
                                </button>
                                {showCopyNotification && (
                                    <div className="toolprimerdesign-copy-tooltip">
                                        CSV copied to clipboard
                                    </div>
                                )}
                            </div>
                        </div>
                        <pre className="toolprimerdesign-csv">
                            {getOrderCSV()}
                        </pre>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ToolPrimerDesign;
