import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar.js";
import ChatInput from "./ChatInput.js";
import ChatMessages from "./ChatMessages.js";
import ArtifactPanel from "./ArtifactPanel.js";
import { useSelector, useDispatch } from "react-redux";
import {
  populateSampleData,
  setPopupMessage,
} from "../store/user.js";
import {
  updateArtifact,
  updateModal,
} from "../store/project.js";
import CreateProjectModal from "./CreateProjectModal.js";
import RenameProjectModal from "./RenameProjectModal.js";
import DeleteProjectModal from "./DeleteProjectModal.js";
import FileUploadModal from "./FileUploadModal.js";
import DeleteFileFromProjectModal from "./DeleteFileFromProjectModal.js";
import { BiHelpCircle } from "react-icons/bi";
import OrderDNA from "./OrderDNA.js";

const ProtocolHelpMenu = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleItemClick = (url, isEmail = false) => {
    if (isEmail) {
      window.location.href = `mailto:${url}`;
    } else {
      window.open(url, '_blank');
    }
    onClose();
  };

  return (
    <div className="help-menu" role="menu" aria-label="Help menu">
      <button className="help-menu-item" role="menuitem" onClick={() => handleItemClick('https://www.labkick.ai/terms_of_service_labkick.html')}>
        Terms of Service
      </button>
      <button className="help-menu-item" role="menuitem" onClick={() => handleItemClick('https://www.labkick.ai/privacy_policy_labkick.html')}>
        Privacy Policy
      </button>
      <button className="help-menu-item" role="menuitem" onClick={() => handleItemClick('support@labkick.ai', true)}>
        Contact Us
      </button>
      <button className="help-menu-item" role="menuitem" onClick={() => handleItemClick('https://www.labkick.ai/tutorial')}>
        Tutorial
      </button>
      <button className="help-menu-item" role="menuitem" onClick={() => handleItemClick('https://www.labkick.ai/appnotes')}>
        App Notes
      </button>
    </div>
  );
};

function ProtocolPage() {
  const dispatch = useDispatch();
  const { artifact, currentProjectId, modal, projectList } = useSelector((state) => state.project);
  const isSidebarVisible = useSelector((state) => state.project.isSidebarVisible);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const design = useSelector((state) => state.project.design);
  const userStatus = useSelector((state) => state.user.status);
  const projectStatus = useSelector((state) => state.project.status);
  const isCheckingAuth = useSelector((state) => state.user.isCheckingAuth);

  // State
  const [articleId, setArticleId] = useState(null);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Handlers
  const handleSampleClick = (sampleNumber) => {
    dispatch(populateSampleData({ userId, sampleNumber }));
  };

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const removeQueryParams = () => {
    const url = new URL(window.location);
    url.searchParams.delete("success");
    url.searchParams.delete("canceled");
    window.history.replaceState({}, document.title, url.pathname);
  };

  // Article ID handling
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const articleParam = Object.keys(Object.fromEntries(searchParams)).find(key => key.startsWith('article_'));

    if (articleParam) {
      const id = articleParam.split('_')[1];
      localStorage.setItem('pendingArticleId', id);
      setArticleId(id);
    } else {
      const pendingId = localStorage.getItem('pendingArticleId');
      if (pendingId) {
        setArticleId(pendingId);
      }
    }
  }, []);

  useEffect(() => {
    if (articleId && token) {
      dispatch(populateSampleData({ userId, sampleNumber: 1 }));
      localStorage.removeItem('pendingArticleId');
      if (userId) {
        setArticleId(null);
      }
    }
  }, [articleId, token, userId, dispatch]);

  // Payment handling
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("success") === "true") {
      dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
      removeQueryParams();
    } else if (searchParams.get("canceled") === "true") {
      dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
      removeQueryParams();
    }
  }, [dispatch]);

  // Help menu handling
  useEffect(() => {
    const handleMouseLeave = (event) => {
      const helpContainer = document.querySelector('.help-container');
      if (helpContainer && !helpContainer.contains(event.relatedTarget)) {
        setIsHelpMenuOpen(false);
      }
    };

    const helpContainer = document.querySelector('.help-container');
    if (helpContainer) {
      helpContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (helpContainer) {
        helpContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  // Initial loading state
  useEffect(() => {
    if (!isCheckingAuth) {
      if (userStatus === 'succeeded' && projectStatus === 'succeeded') {
        setIsInitialLoading(false);
      }
    } else if (userStatus === 'unauthenticated') {
      setIsInitialLoading(false);
    }
  }, [isCheckingAuth, userStatus, projectStatus]);

  // Keyboard handling
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        setIsHelpMenuOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div className={`container ${isSidebarVisible ? "" : "sidebar-collapsed"}`} role="main">
      <Sidebar />
      {isInitialLoading && (
        <div className="initial-loading-overlay" role="alert" aria-busy="true">
          <div className="loading-spinner" aria-label="Loading content"></div>
        </div>
      )}
      <div className="main-content-wrapper">
        <section className={`main ${artifact ? "with-artifact" : ""}`}>
          {(!isInitialLoading) && projectList.length === 0 ? (
            <div className="empty-chat-container" role="region" aria-label="Welcome section">
              <img src="/images/K-mark.svg" width={135} height={135} alt="LabKick Logo" />
              <h1>LabKick</h1>
              <h3>
                {token
                  ? "Welcome! Click a button below to get kicking."
                  : articleId
                    ? "Please sign up or log in to view the Project"
                    : "Please sign up or log in to get kicking!"}
              </h3>
              {token && (
                <div className="large-buttons-container">
                  <button className="large-button" onClick={() => dispatch(updateModal({ name: "create_project", data: null }))} aria-label="Create new project">
                    <img src="/images/new_project.svg" alt="" className="button-icon" aria-hidden="true" />
                    Create Project
                  </button>
                  <button className="large-button" onClick={() => handleSampleClick(1)} aria-label="Tutorial">
                    <img src="/images/key.svg" alt="" className="button-icon" aria-hidden="true" />
                    Tutorial
                  </button>
                  <button className="large-button" onClick={() => handleSampleClick(2)} aria-label="Example">
                    <img src="/images/dna-archive-fix.svg" alt="" className="button-icon" aria-hidden="true" />
                    Example
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <ChatMessages />
              <div className="input-design-container">
                <ChatInput />
              </div>
            </>
          )}
        </section>
        {artifact && <ArtifactPanel onClose={() => dispatch(updateArtifact(null))} />}
      </div>

      {/* Modals */}
      {modal.name === "create_project" && <CreateProjectModal />}
      {modal.name === "rename_project" && <RenameProjectModal />}
      {modal.name === "delete_project" && <DeleteProjectModal />}
      {(modal.name === "upload_general" || modal.name === "upload_project") && <FileUploadModal />}
      {modal.name === "delete_file" && <DeleteFileFromProjectModal isOpen={true} />}
      {modal.name === "order_dna" && <OrderDNA />}

      {/* Help Menu */}
      {!modal.name && (
        <div className="help-container">
          <button
            className="help-button"
            onClick={() => setIsHelpMenuOpen(!isHelpMenuOpen)}
            aria-expanded={isHelpMenuOpen}
            aria-haspopup="menu"
            aria-label="Help menu"
          >
            <BiHelpCircle size={24} color="black" aria-hidden="true" />
          </button>
          <ProtocolHelpMenu isOpen={isHelpMenuOpen} onClose={() => setIsHelpMenuOpen(false)} />
        </div>
      )}
    </div>
  );
}

export default ProtocolPage;