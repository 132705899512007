import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { closeModal, uploadFile, updateModal, setIsLoadingFiles, setLoadingMessage } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/FileUploadModal.css";
import { setPopupMessage } from "../store/user.js";
import { checkFilenameUnique } from "../api.js";

function FileUploadModal() {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();
    const { modal, currentProjectId } = useSelector((state) => state.project);
    const user = useSelector((state) => state.user.data);
    const chat = useSelector((state) => state.project.chat)[currentProjectId];
    const chatId = chat.chat_id;
    const [annotateOption, setAnnotateOption] = useState("annotate");
    const [uploadType, setUploadType] = useState("file");
    const [sequenceName, setSequenceName] = useState("");
    const [sequence, setSequence] = useState("");
    const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
    const [fileSpecs, setFileSpecs] = useState({});
    const [sequenceError, setSequenceError] = useState("");
    const [sequenceTopology, setSequenceTopology] = useState("linear");
    const token = useSelector(state => state.user.access_token);

    const maxNameLength = 50;
    const validNameRegex = /^[ a-zA-Z0-9_:\-\.#$]+$/;

    const validateFileName = (fileName) => {
        if (fileName.length > maxNameLength) {
            return "File name must be 30 characters or less.";
        }
        if (fileName.split('.').length > 2) {
            return "File name must contain one or less period (.)";
        }
        if (!validNameRegex.test(fileName)) {
            return "File name contains invalid characters. Only letters, numbers, and _:-#$ are allowed.";
        }
        return null;
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        const MAX_FILES_PER_UPLOAD = 20;

        if (acceptedFiles.length > MAX_FILES_PER_UPLOAD) {
            setErrorMessage(`You can only upload up to ${MAX_FILES_PER_UPLOAD} files at a time.`);
            return;
        }

        const processedFiles = await Promise.all(acceptedFiles.map(async (file) => {
            const extension = '.' + file.name.split('.').pop().toLowerCase();
            const isGenBankOrEmbl = ['.gb', '.gbk', '.genbank', '.embl'].includes(extension);

            if (isGenBankOrEmbl) {
                const topology = await getTopologyFromFile(file);
                setFileSpecs(prev => ({
                    ...prev,
                    [file.name]: {
                        ...prev[file.name],
                        topology
                    }
                }));
            }
            return file;
        }));

        setFiles((prevFiles) => [...prevFiles, ...processedFiles]);
    }, []);

    const DNA_FILE_FORMAT = {
        ".fa": "fasta",
        ".fasta": "fasta",
        ".fna": "fasta",
        ".txt": "fasta",
        ".gb": "genbank",
        ".gbk": "genbank",
        ".genbank": "genbank",
        ".embl": "embl",
        ".fastq": "fastq",
        ".fq": "fastq",
        ".ig": "ig",
    }


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUploadTypeChange = (type) => {
        setUploadType(type);
        setErrorMessage("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        let errorMessages = [];

        if (uploadType === "file") {
            const invalidNames = files.map(file => {
                const error = validateFileName(file.name);
                return error ? `${file.name}: ${error}` : null;
            }).filter(Boolean);

            if (invalidNames.length > 0) {
                isValid = false;
                errorMessages.push(`Invalid file names detected:\n${invalidNames.join("\n")}`);
            }
        } else if (uploadType === "sequence") {
            const fileNameError = validateFileName(sequenceName);
            if (fileNameError) {
                isValid = false;
                errorMessages.push(fileNameError);
            }
        }

        if (!isValid) {
            setErrorMessage(errorMessages.join("\n"));
            return;
        }

        // If all validations pass, proceed with file upload
        console.log("handleFileUpload called with:", { currentProjectId, user, chatId, annotateOption });
        const filesWithSpecs = files.map(file => ({
            file,
            topology: fileSpecs[file.name]?.topology || 'linear'
        }));

        handleFileUpload(filesWithSpecs, currentProjectId, chatId, user.user_id, annotateOption);
    };

    const DNA_FILE_EXTENSIONS = [
        ".fa", ".fasta", ".fna", ".gb", ".gbk", ".genbank", ".embl", ".fastq", ".fq", ".ig", ".txt"
    ];

    const handleFileUpload = async (filesWithSpecs, projectId, chatId, userId, annotate) => {
        let filesToUpload = [];

        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        const maxSequenceLength = 5e6; // 5 million bases

        if (uploadType === "file") {
            // Check for duplicate filenames using the new API endpoint
            const duplicateFiles = [];
            for (const fileData of filesWithSpecs) {
                const isUnique = await checkFilenameUnique(userId, fileData.file.name, token);
                if (!isUnique) {
                    duplicateFiles.push(fileData);
                }
            }

            if (duplicateFiles.length > 0) {
                setErrorMessage(`Duplicate file names detected: ${duplicateFiles.map(f => f.file.name).join(", ")}. Please rename these files.`);
                console.log("Duplicate files detected:", duplicateFiles);
                return;
            }

            // Rest of your existing file validation logic
            const totalSize = filesWithSpecs.reduce((acc, fileData) => acc + fileData.file.size, 0);

            if (totalSize > maxSize) {
                setErrorMessage("File upload size too big. Maximum total allowed size is 5MB.");
                return;
            }

            const invalidFiles = filesWithSpecs.filter(fileData => {
                const extension = '.' + fileData.file.name.split('.').pop().toLowerCase();
                return !DNA_FILE_EXTENSIONS.includes(extension);
            });

            if (invalidFiles.length > 0) {
                setErrorMessage(`Invalid file types detected: ${invalidFiles.map(f => f.file.name).join(", ")}. 
                    Please upload files with the following extensions: ${DNA_FILE_EXTENSIONS.join(", ")}`);
                console.log("Invalid files detected:", invalidFiles);
                return;
            }

            filesToUpload = filesWithSpecs;

        } else if (uploadType === "sequence") {
            if (!sequenceName || !sequence) {
                setErrorMessage("Please provide both a sequence name and sequence.");
                return;
            }

            const cleanedSequence = sequence.replace(/[^atcgATCG]/g, '').toUpperCase();
            if (cleanedSequence.length === 0) {
                setErrorMessage("The sequence must contain valid DNA bases (A, T, C, G).");
                return;
            }

            if (cleanedSequence.length > maxSize) {
                setErrorMessage("Sequence is too long. Max allowed is 5 million bases.");
                return;
            }

            // Check for duplicate sequence name
            const fileName = `${sequenceName.split('.')[0]}.fasta`;
            const isUnique = await checkFilenameUnique(userId, fileName, token);
            if (!isUnique) {
                setErrorMessage(`A file with the name "${fileName}" already exists. Please choose a different name.`);
                return;
            }

            const fastaContent = `>${sequenceName.split('.')[0]}\n${cleanedSequence}`;
            const fastaFile = new File([fastaContent], fileName, { type: "text/plain" });

            filesToUpload = [{
                file: fastaFile,
                topology: sequenceTopology,
                parentFileId: null
            }];
        }

        setErrorMessage(""); // Clear error message if no issues

        try {
            if (modal.name === 'upload_general') {
                projectId = "no_project";
            }
            dispatch(setIsLoadingFiles(true));
            const response = await dispatch(uploadFile({ projectId, files: filesToUpload, chatId, userId, annotate })).unwrap();
            if (response.error && response.error.length > 0) {
                dispatch(setPopupMessage(response.error));
            }
            // Only update modal if upload was successful
            dispatch(updateModal({ name: modal.name.replace('upload', 'dna_archive') }));
        } catch (error) {
            console.error("Failed to upload files:", error);
            setErrorMessage("Failed to upload files. Please try again.");
        }
    };

    const removeFile = (fileToRemove) => {
        const newFiles = files.filter((file) => file !== fileToRemove);
        setFiles(newFiles);
        if (newFiles.length === 0) {
            setErrorMessage("");
        }
    };

    const formatFileSize = (bytes) => {
        if (bytes < 1024) {
            return `${bytes} bytes`;
        } else if (bytes < 1024 * 1024) {
            return `${Math.floor(bytes / 1024)} KB`;
        } else {
            return `${Math.floor(bytes / (1024 * 1024))} MB`;
        }
    };

    const handleFileSpecChange = (file, spec, value) => {
        setFileSpecs(prev => ({
            ...prev,
            [file.name]: {
                ...prev[file.name],
                [spec]: value
            }
        }));
    };

    const renderFileSpecs = (file) => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        const isGenBankOrEmbl = ['.gb', '.gbk', '.genbank', '.embl'].includes(extension);
        const selectValue = fileSpecs[file.name]?.topology || 'linear';

        return {
            topologyDropdown: (
                <select
                    className="topology-select"
                    value={selectValue}
                    onChange={(e) => handleFileSpecChange(file, 'topology', e.target.value)}
                    disabled={isGenBankOrEmbl}
                >
                    <option value="linear">Linear</option>
                    <option value="circular">Circular</option>
                </select>
            )
        };
    };

    const handleSequenceNameChange = (e) => {
        setSequenceName(e.target.value);
    };

    const getTopologyFromFile = async (file) => {
        try {
            const text = await file.text();
            console.log("File content first line:", text.split('\n')[0]); // Debug log

            // More precise LOCUS line parsing
            const locusMatch = text.match(/LOCUS\s+\S+\s+\d+\s+bp\s+DNA\s+(linear|circular)/i);
            console.log("Locus match:", locusMatch); // Debug log

            if (locusMatch) {
                console.log("Found topology:", locusMatch[1]); // Debug log
                return locusMatch[1].toLowerCase();
            }

            // Fallback patterns
            const topologyMatch = text.match(/\btopology="?(circular|linear)"?/i) ||
                text.match(/\bTOPOLOGY:\s*(circular|linear)/i);

            if (topologyMatch) {
                return topologyMatch[1].toLowerCase();
            }

            return 'auto';
        } catch (error) {
            console.error('Error reading file:', error);
            return 'auto';
        }
    };

    // Add keyboard handler for dropzone
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.currentTarget.click();
        }
    };

    const validateSequence = (seq) => {
        // Check if sequence contains anything other than ATCG (case insensitive)
        const invalidChars = seq.replace(/[atcgATCG]/g, '');
        if (invalidChars.length > 0) {
            // Create a more user-friendly display of invalid characters
            const uniqueInvalidChars = [...new Set(invalidChars)]
                .map(char => char === ' ' ? 'spaces' : `'${char}'`)
                .filter((value, index, self) => self.indexOf(value) === index)
                .join(', ');
            return `Invalid characters found: ${uniqueInvalidChars}. Only A, T, C, G letters are allowed.`;
        }
        return "";
    };

    const handleSequenceChange = (e) => {
        const newSequence = e.target.value;
        setSequence(newSequence);
        setSequenceError(validateSequence(newSequence));
    };

    if (modal.name !== 'upload_general' && modal.name !== 'upload_project') {
        return null;
    }

    return (
        <div
            className="file-upload-modal-overlay"
            role="dialog"
            aria-modal="true"
            aria-labelledby="upload-modal-title"
        >
            <div className="file-upload-modal-dialog">
                <h2 id="upload-modal-title" className="visually-hidden">Upload Files</h2>
                <div className="upload-type-toggle" role="tablist">
                    <button
                        className={`toggle-button ${uploadType === "file" ? "active" : ""}`}
                        onClick={() => handleUploadTypeChange("file")}
                        role="tab"
                        aria-selected={uploadType === "file"}
                        aria-controls="file-upload-panel"
                    >
                        Import Sequence(s)
                    </button>
                    <button
                        className={`toggle-button ${uploadType === "sequence" ? "active" : ""}`}
                        onClick={() => handleUploadTypeChange("sequence")}
                        role="tab"
                        aria-selected={uploadType === "sequence"}
                        aria-controls="sequence-upload-panel"
                    >
                        Create Sequence
                    </button>
                </div>
                {errorMessage && (
                    <div
                        className="error-message"
                        role="alert"
                        aria-live="polite"
                    >
                        <p>{errorMessage}</p>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    {uploadType === "file" ? (
                        <>
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>Drag and drop some files here, or click to select files</p>
                                )}
                            </div>
                            {files.length > 0 && (
                                <div className="file-list">
                                    <h3>Selected Files:</h3>
                                    <ul>
                                        {/* Header Row */}
                                        <li className="file-list-header">
                                            <div>File Name</div>
                                            <div>Topology</div>
                                        </li>
                                        {/* File Rows */}
                                        {files.map((file, index) => (
                                            <li key={index}>
                                                <div>
                                                    {file.name} - {formatFileSize(file.size)}
                                                    <button type="button" onClick={() => removeFile(file)}>
                                                        Remove
                                                    </button>
                                                </div>
                                                <div>{renderFileSpecs(file).topologyDropdown}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    ) : (
                        <div
                            id="sequence-upload-panel"
                            className="sequence-input"
                            role="tabpanel"
                            aria-labelledby="sequence-upload-tab"
                        >
                            <input
                                id="sequence-name"
                                type="text"
                                placeholder="Sequence Name"
                                value={sequenceName}
                                onChange={handleSequenceNameChange}
                                aria-invalid={errorMessage && errorMessage.includes("name")}
                                aria-required="true"
                            />
                            <textarea
                                id="sequence-text"
                                placeholder="Sequence Text"
                                value={sequence}
                                onChange={handleSequenceChange}
                                aria-invalid={!!sequenceError}
                                aria-required="true"
                            />
                            {sequenceError && (
                                <div className="sequence-error" role="alert">
                                    {sequenceError}
                                </div>
                            )}
                            <div className="file-upload-radio-group" role="radiogroup" aria-label="Topology options">
                                <label>
                                    <input
                                        type="radio"
                                        value="linear"
                                        checked={sequenceTopology === "linear"}
                                        onChange={(e) => setSequenceTopology(e.target.value)}
                                        name="sequence-topology"
                                    />
                                    Linear
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="circular"
                                        checked={sequenceTopology === "circular"}
                                        onChange={(e) => setSequenceTopology(e.target.value)}
                                        name="sequence-topology"
                                    />
                                    Circular
                                </label>
                            </div>
                        </div>
                    )}
                    <div className="file-upload-radio-group" role="radiogroup" aria-label="Annotation options">
                        <label>
                            <input
                                type="radio"
                                value="annotate"
                                checked={annotateOption === "annotate"}
                                onChange={() => setAnnotateOption("annotate")}
                                name="annotation-option"
                            />
                            Annotate files
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="no_annotate"
                                checked={annotateOption === "no_annotate"}
                                onChange={() => setAnnotateOption("no_annotate")}
                                name="annotation-option"
                            />
                            Don't annotate
                        </label>
                    </div>
                    <div className="annotation-info" role="note">
                        Please note that if you upload a .fasta with multiple sequences and do not split them, we will keep them as one file and not annotate them.
                        <br /><br />
                        We keep your sequences safe and annotate them on our servers. Annotation takes ~2-10 seconds per file. Please note that if you request your files to be annotated, we will save them as .gb files in LabKick.
                    </div>
                    <div className="file-upload-modal-buttons">
                        <button
                            type="button"
                            onClick={() => dispatch(updateModal({ name: modal.name.replace('upload', 'dna_archive') }))}
                            className="file-upload-cancel-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="file-upload-confirm-button"
                            disabled={uploadType === "sequence" && (!!sequenceError || !sequence)}
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FileUploadModal;