import React, { useState, useRef, useEffect } from "react";
import {
  BiSolidUserCircle,
  BiFolderOpen,
  BiFolder,
  BiFile,
} from "react-icons/bi";
import "../style/Sidebar.css";
import { processProjectFiles } from "../helpers.js";
import FileExplorerModal from "./FileExplorerModal.js";
import UserPreferencesModal from "./UserPreferencesModal.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentProjectId,
  updateArtifact,
  updateModal,
  toggleSidebar,
  fetchProjects,
  fetchChat,
  resetChat,
  fetchDesign,
  fetchArtifactData,
  setIsChatLoading,
  setIsProjectLoading,
  fetchNextProjects,
  setProjectInitialized,
  setProjectFullyLoaded,
} from "../store/project.js";
import { downloadFiles, fetchProtocols } from "../api.js";
import { useAuth0 } from "@auth0/auth0-react";
import { updateAccessToken, fetchUser, fetchUserCredits, setUserStatus, setPopupMessage, setInitialized } from "../store/user.js";
import { BiPencil } from "react-icons/bi"; // Add this import
import PaymentModal from "./PaymentModal.js"; // Add this import
import GroupManagementModal from "./GroupManagementModal.js";
// import sidebarIcon from "../icons/sidebar.svg";

function Sidebar() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [projectFiles, setProjectFiles] = useState({});

  const dropdownRef = useRef(null);
  const projectItemRef = useRef(null);
  const userDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.access_token);

  const isVisible = useSelector((state) => state.project.isSidebarVisible);
  const user_id = useSelector((state) => state.user.user_id);
  const usageCredits = useSelector((state) => state.user.usageCredits);
  const isInitialized = useSelector((state) => state.user.isInitialized);

  const { projectList, currentProjectId, modal, design } = useSelector(
    (state) => state.project
  );

  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const [hasFetchedProjects, setHasFetchedProjects] = useState(false);

  const chat = useSelector((state) => state.project.chat);

  const projectsNextToken = useSelector((state) => state.project.projectsNextToken);

  const isProjectInitialized = useSelector((state) => state.project.isProjectInitialized);
  const initializedProjects = useSelector((state) => state.project.initializedProjects);

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const handleProjectClick = (event, projectId) => {
    // Only change the project if the click is outside the three dots
    if (!event.target.closest(".project-menu-icon")) {
      dispatch(setIsProjectLoading(true));
      // wait 500ms before setting the current project id
      setTimeout(() => {
        dispatch(setCurrentProjectId(projectId));
      }, 100);
      if (design) {
        dispatch(updateArtifact({ ...design, isDesign: true }));
      }
      setTimeout(() => {
        dispatch(setIsProjectLoading(false));
      }, 300);
    }
  };

  const toggleDropdown = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    setActiveDropdown((prevActiveDropdown) =>
      prevActiveDropdown === projectId ? null : projectId
    );
  };

  // Handle Dropdown for Rename / Open Modal
  const handleProjectRename = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    const projectToRename = projectList.find((p) => p.project_id === projectId);
    dispatch(updateModal({ name: "rename_project", data: projectToRename }));
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete / Open Modal
  const handleDeleteProject = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    dispatch(
      updateModal({
        name: "delete_project",
        data: projectList.find((p) => p.project_id === projectId),
      })
    );
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete File / Open Modal
  const handleFileDelete = (event, fileData) => {
    event.stopPropagation();
    dispatch(updateModal({ name: "delete_file", data: fileData }));
  };

  const handleProjectDownload = (event, projectId) => {
    event.stopPropagation();
    downloadFiles(projectId, "project", token);
    setActiveDropdown(null);
  };

  const handleProtocolExport = async (event, projectId) => {
    event.stopPropagation();
    try {
      // Get the current project from Redux store
      const currentProject = projectList.find(p => p.project_id === projectId);
      if (!currentProject) {
        throw new Error('Project not found');
      }

      // Filter for markdown files in the outputs
      const protocolFiles = Object.entries(currentProject.files || {})
        .filter(([_, file]) =>
          file.file_type === "Output" &&
          file.file_name.endsWith('.md')
        )
        .map(([fileId, file]) => ({
          fileId,
          fileName: file.file_name
        }))
        .sort((a, b) => a.fileName.localeCompare(b.fileName));

      const content = await fetchProtocols(protocolFiles, token);

      // Create file download
      const blob = new Blob([content], { type: 'text/markdown' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'protocols.md';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Copy to clipboard
      await navigator.clipboard.writeText(content);
      dispatch(setPopupMessage('Protocols exported and copied to clipboard'));

    } catch (error) {
      console.error('Error exporting protocols:', error);
      dispatch(setPopupMessage('Failed to export protocols'));
    }
    setActiveDropdown(null);
  };

  const toggleFolder = (folderId) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const renderProjectFiles = (projectId) => {
    if (projectId !== currentProjectId) return null;
    const files = projectFiles[projectId] || [];
    return (
      <ul className="file-tree">
        <div className="design-button-container">
          <button className="design-button" onClick={handleDesignClick} aria-label="Open design view">
            <BiPencil size={20} />
            Design
          </button>
        </div>

        {renderFileTree(files)}
      </ul>
    );
  };

  const renderFileTree = (items) => {
    return items.map((item) => {
      if (item.type === "file") {
        let displayName = item.name.startsWith("CONSTRUCT_")
          ? item.name.replace("CONSTRUCT_", "")
          : item.name;

        // Remove .md and .csv extensions for files in the output folder
        if (item.file_type !== "DNA" && item.file_type !== "TargetConstruct") {
          if (displayName.endsWith('.md')) {
            displayName = displayName.slice(0, -3);
          } else if (displayName.endsWith('.csv')) {
            displayName = displayName.slice(0, -4);
          }
        }

        // Check if file is in Outputs or Constructs folder
        const isProtectedFile = item.file_type != "DNA";

        return (
          <li
            key={item.uuid}
            className="file-item"
            onClick={(e) => handleFileClick(e, item)}
            title={item.name}
            role="menuitem"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleFileClick(e, item);
              }
            }}
            aria-label={`File: ${displayName}`}
          >
            <BiFile className="file-item-icon" />
            <span className="file-item-name">{displayName}</span>
            <img
              src="/images/three-dots.svg"
              alt="Menu"
              className="file-menu-icon"
              onClick={(e) => toggleDropdown(e, item.uuid)}
            />
            {activeDropdown === item.uuid && (
              <div
                ref={dropdownRef}
                className="file-dropdown"
                onMouseLeave={() => setActiveDropdown(null)}
              >
                {!isProtectedFile && (
                  <button
                    className="dropdown-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFileDelete(e, item);
                    }}
                  >
                    <span>Remove</span>
                    <img
                      src="/images/trash.svg"
                      alt="Remove"
                      className="dropdown-icon"
                    />
                  </button>
                )}
                <button
                  className="dropdown-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFiles(item.uuid, "file", token);
                    setActiveDropdown(null);
                  }}
                >
                  <span>Download</span>
                  <img
                    src="/images/download.svg"
                    alt="Download"
                    className="dropdown-icon"
                  />
                </button>
              </div>
            )}
          </li>
        );
      } else if (item.type === "folder") {
        const isOpen = openFolders[item.id];
        return (
          <React.Fragment key={item.id}>
            <li
              className="folder-item"
              onClick={() => toggleFolder(item.id)}
              title={item.name}
              role="treeitem"
              tabIndex={0}
              onKeyDown={(e) => handleFolderKeyDown(e, item.id)}
              aria-expanded={isOpen}
              aria-label={`Folder: ${item.name}`}
            >
              {isOpen ? (
                <BiFolderOpen className="file-item-icon" />
              ) : (
                <BiFolder className="file-item-icon" />
              )}
              <span className="file-item-name">{item.name}</span>
              {item.name === "Parts" && (
                <img
                  src="/images/plus.svg"
                  alt="Add File"
                  className="folder-add-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      updateModal({
                        name: "dna_archive_project",
                        data: { projectId: currentProjectId },
                      })
                    );
                  }}
                />
              )}
            </li>
            {isOpen && (
              <ul className="indented">{renderFileTree(item.children)}</ul>
            )}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const handleFileClick = (event, fileData) => {
    event.stopPropagation();
    const fileId = fileData.uuid;
    const fileName = fileData.name;
    const fileType = fileData.file_type;

    // Check if file is in backbone bin
    const isBackbone = design?.bins?.some(bin =>
      bin.is_backbone &&
      bin.part_names &&
      Object.keys(bin.part_names).includes(fileName)
    ) || false;

    console.log("isBackbone:", isBackbone);

    dispatch(fetchArtifactData({
      fileId,
      transformationType: "None",
      sequenceName: "None",
      restrictionEnzymes: design?.restriction_enzymes || [],
      isBackbone,
      design,
      fileType
    }));
  };

  const handlePaymentClick = () => {
    dispatch(updateModal({ name: "payments", data: null }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        projectItemRef.current &&
        !projectItemRef.current.contains(event.target) &&
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
        setUserDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      const currentProject = projectList.find(
        (p) => p.project_id === currentProjectId
      );
      if (currentProject) {
        const files = processProjectFiles(currentProject);

        // Sort files within each folder alphabetically
        const sortFiles = (items) => {
          return items.sort((a, b) => {
            // First sort by type (folders before files)
            if (a.type !== b.type) {
              return a.type === 'folder' ? -1 : 1;
            }
            // For folders, enforce specific order: Parts, Protocols, Constructs
            if (a.type === 'folder' && b.type === 'folder') {
              const folderOrder = {
                'Parts': 1,
                'Protocols': 2,
                'Constructs': 3
              };
              return folderOrder[a.name] - folderOrder[b.name];
            }
            // Then sort by name alphabetically
            return a.name.localeCompare(b.name);
          }).map(item => {
            if (item.type === 'folder' && item.children) {
              return {
                ...item,
                children: sortFiles(item.children)
              };
            }
            return item;
          });
        };

        const sortedFiles = sortFiles(files);

        setProjectFiles((prevFiles) => ({
          ...prevFiles,
          [currentProjectId]: sortedFiles,
        }));

        // Set the folder open states based on file count
        const folderStates = {};
        sortedFiles.forEach((folder) => {
          if (folder.type === "folder") {
            const fileCount = folder[`${folder.name.toLowerCase()}FileCount`];
            folderStates[folder.id] = fileCount <= 20;
          }
        });
        setOpenFolders((prev) => ({
          ...prev,
          ...folderStates,
        }));
      }
    }
  }, [currentProjectId, projectList]);

  const handleAuthAction = () => {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      loginWithRedirect();
    }
  };

  const fetchAccessToken = async () => {
    try {
      const accessTokenTmp = await getAccessTokenSilently();
      return accessTokenTmp;
    } catch (error) {
      console.error("Error getting access token:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessTokenTmp = await fetchAccessToken();
        if (accessTokenTmp) {
          dispatch(updateAccessToken(accessTokenTmp));
          const result = await dispatch(
            fetchUser({ token: accessTokenTmp, user_auth0: user })
          );

          if (result?.payload?.data?.data?.user_id) {
            // Only fetch projects if not already initialized
            if (!isProjectInitialized) {
              dispatch(fetchProjects(result.payload.data.data.user_id));
              dispatch(setProjectInitialized());
            }
            dispatch(setInitialized(true));
            setHasFetchedProjects(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (isAuthenticated && !hasFetchedProjects && !isInitialized) {
      fetchUserData();
    } else if (!isAuthenticated && !isLoading) {
      dispatch(setUserStatus("unauthenticated"));
    }

  }, [isAuthenticated, hasFetchedProjects, isLoading, isInitialized, isProjectInitialized]);

  useEffect(() => {
    if (isAuthenticated && user_id) {
      dispatch(fetchUserCredits());
    }
  }, [isAuthenticated, user_id, dispatch]);

  useEffect(() => {
    if (currentProjectId) {
      // Only fetch if project hasn't been fully loaded yet
      if (!initializedProjects[currentProjectId]) {
        if (!chat[currentProjectId]) {
          dispatch(setIsChatLoading(true));
        }
        dispatch(fetchChat(currentProjectId));
        dispatch(fetchDesign(currentProjectId));
        if (design?.file_id) {
          dispatch(fetchArtifactData({ fileId: design.file_id, transformationType: "None" }));
        }
        if (design) {
          dispatch(updateArtifact({ ...design, isDesign: true }));
        }
        // Mark this project as fully loaded
        dispatch(setProjectFullyLoaded(currentProjectId));
      }
    } else {
      dispatch(resetChat(currentProjectId));
    }
  }, [currentProjectId]);

  useEffect(() => {
    console.log("Chat:", chat[currentProjectId]);
  }, [chat]);

  const toggleUserDropdown = (event) => {
    event.stopPropagation();
    setUserDropdownOpen(!userDropdownOpen);
  };

  // Add keyboard handler for project items
  const handleProjectKeyDown = (event, projectId) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleProjectClick(event, projectId);
    }
  };

  // Add keyboard handler for folder items
  const handleFolderKeyDown = (event, folderId) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleFolder(folderId);
    }
  };

  const handleLoadMore = () => {
    if (projectsNextToken) {
      dispatch(fetchNextProjects(projectsNextToken));
    }
  };

  return (
    <>
      {!isVisible && (
        <button
          className="sidebar-toggle"
          onClick={() => dispatch(toggleSidebar())}
          aria-label="Toggle sidebar menu"
        >
          <img src="/images/sidebar.svg" alt="" role="presentation" />
        </button>
      )}
      <section
        className={`sidebar ${isVisible ? "" : "hidden"}`}
        role="navigation"
        aria-label="Project navigation"
      >
        <div className="sidebar-content">
          <div className="sidebar-top">
            <div className="sidebar-header">
              <img
                src="/images/sidebar.svg"
                alt="Sidebar"
                className="sidebar-icon"
                onClick={() => dispatch(toggleSidebar())}
              />
            </div>
            <div className="project-header">
              <div className="project-header-name">Projects</div>
              <img
                src="/images/new_project.svg"
                alt="Create new project"
                className="new-project-icon"
                onClick={() => dispatch(updateModal({ name: "create_project", data: null }))}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    dispatch(updateModal({ name: "create_project", data: null }));
                  }
                }}
              />
            </div>
            <div className="project-list">
              <ul>
                {projectList.length > 0 ? (
                  <>
                    {projectList.map((project) => (
                      <div
                        key={project.project_id}
                        className={`project-group ${project.project_id === currentProjectId
                          ? "selected"
                          : ""
                          }`}
                      >
                        <li
                          className={`project-item ${project.project_id === currentProjectId
                            ? "selected"
                            : ""
                            }`}
                          ref={projectItemRef}
                          onClick={(event) =>
                            handleProjectClick(event, project.project_id)
                          }
                          title={project.project_name}
                          role="menuitem"
                          tabIndex={0}
                          onKeyDown={(e) => handleProjectKeyDown(e, project.project_id)}
                          aria-selected={project.project_id === currentProjectId}
                        >
                          <span className="project-name">
                            {project.project_name}
                          </span>
                          <img
                            src="/images/three-dots.svg"
                            alt="Menu"
                            className="project-menu-icon"
                            onClick={(event) =>
                              toggleDropdown(event, project.project_id)
                            }
                          />
                          {activeDropdown === project.project_id && (
                            <div
                              ref={dropdownRef}
                              className="project-dropdown"
                              onMouseLeave={() => setActiveDropdown(null)}
                              role="menu"
                              aria-label="Project actions"
                            >
                              <button
                                className="dropdown-button"
                                onClick={(event) =>
                                  handleProjectRename(event, project.project_id)
                                }
                              >
                                <span>Rename</span>
                                <img
                                  src="/images/pencil.svg"
                                  alt="Rename"
                                  className="dropdown-icon"
                                />
                              </button>
                              <button
                                className="dropdown-button"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  dispatch(
                                    updateModal({
                                      name: "dna_archive_project",
                                      data: { projectId: project.project_id },
                                    })
                                  );
                                  setActiveDropdown(null);
                                }}
                              >
                                <span>Add File</span>
                                <img
                                  src="/images/plus.svg"
                                  alt="Add File"
                                  className="dropdown-icon"
                                />
                              </button>
                              <button
                                className="dropdown-button"
                                onClick={(event) =>
                                  handleProjectDownload(event, project.project_id)
                                }
                              >
                                <span>Download</span>
                                <img
                                  src="/images/download.svg"
                                  alt="Download"
                                  className="dropdown-icon"
                                />
                              </button>
                              <button
                                className="dropdown-button"
                                onClick={(event) =>
                                  handleProtocolExport(event, project.project_id)
                                }
                              >
                                <span>Copy Protocols</span>
                                <img
                                  src="/images/copy-csv.svg"
                                  alt="Export"
                                  className="dropdown-icon"
                                />
                              </button>
                              <button
                                className="dropdown-button"
                                onClick={(event) =>
                                  handleDeleteProject(event, project.project_id)
                                }
                              >
                                <span>Delete</span>
                                <img
                                  src="/images/trash.svg"
                                  alt="Delete"
                                  className="dropdown-icon"
                                />
                              </button>
                            </div>
                          )}
                        </li>

                        {renderProjectFiles(project.project_id)}
                      </div>
                    ))}
                    {projectsNextToken && (
                      <li className="load-more-item">
                        <button
                          className="load-more-button"
                          onClick={handleLoadMore}
                          aria-label="Load more projects"
                        >
                          Load More
                        </button>
                      </li>
                    )}
                  </>
                ) : (
                  <div></div>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="sidebar-info">
          {isAuthenticated ? (
            <>
              <div
                className="sidebar-info-item"
                onClick={handlePaymentClick}
                style={{ cursor: "pointer" }}
                role="button"
                tabIndex={0}
                aria-label={`Credits: ${usageCredits === 0 ? "Buy Credits" : `${usageCredits} Credits`}`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handlePaymentClick();
                  }
                }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src="/images/credit_card.svg"
                    alt="Coins"
                    className="sidebar-bottom-img"
                  />
                </div>
                <p className="sidebar-bottom-text">
                  {usageCredits === 0 ? "Buy Credits" : usageCredits === 1 ? `${usageCredits} Credit` : `${usageCredits} Credits`}
                </p>
              </div>
              <div
                className="sidebar-info-item sidebar-info-item-dna"
                onClick={() => dispatch(updateModal({ name: "dna_archive_general", data: null }))}
                style={{ cursor: "pointer" }}
                role="button"
                tabIndex={0}
                aria-label="Open DNA sequences"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    dispatch(updateModal({ name: "dna_archive_general", data: null }));
                  }
                }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src="/images/dna-archive-fix.svg"
                    alt="DNA"
                    className="sidebar-bottom-img"
                  />
                </div>
                <p className="sidebar-bottom-text">DNA Sequences</p>
              </div>
              <div
                className="sidebar-info-item"
                onClick={() => dispatch(updateModal({ name: "user_preferences", data: null }))}
                style={{ cursor: "pointer" }}
                role="button"
                tabIndex={0}
                aria-label="Open preferences"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    dispatch(updateModal({ name: "user_preferences", data: null }));
                  }
                }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src="/images/settings.svg"
                    alt="Preferences"
                    className="sidebar-bottom-img"
                  />
                </div>
                <p className="sidebar-bottom-text">Preferences</p>
              </div>
              <div
                className="sidebar-info-item user-dropdown-trigger"
                onClick={toggleUserDropdown}
                ref={userDropdownRef}
                style={{ cursor: "pointer", position: "relative" }}
                role="button"
                aria-haspopup="true"
                aria-expanded={userDropdownOpen}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleUserDropdown(e);
                  }
                }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src={user.name === "Scott Stankey"
                      ? "https://www.labkick.ai/K-mark.svg"
                      : (user.picture || "/images/default-user-icon.png")}
                    alt={user.name === "Scott Stankey" ? "LabKick Team" : user.name}
                    width="20"
                    height="20"
                    className="sidebar-bottom-img user-picture"
                  />
                </div>
                <p className="sidebar-bottom-text">
                  {user.name === "Scott Stankey" ? "LabKick Team" : user.name}
                </p>
                {userDropdownOpen && (
                  <div
                    className="user-dropdown"
                    onMouseLeave={() => setUserDropdownOpen(false)}
                  >
                    <button
                      className="dropdown-button"
                      onClick={() => {
                        logout({
                          logoutParams: {
                            returnTo: window.location.origin
                          }
                        })
                        setUserDropdownOpen(false);
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button
                className="auth-button"
                onClick={() => loginWithRedirect()}
                aria-label="Log in to account"
              >
                Log In
              </button>
              <button
                className="auth-button"
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "signup",
                      prompt: "login",
                    },
                  })
                }
                aria-label="Create new account"
              >
                Sign Up
              </button>
            </>
          )}
        </div>
        {(modal.name === "dna_archive_general" ||
          modal.name === "dna_archive_project" ||
          modal.name === "upload_general" ||
          modal.name === "upload_project") && <FileExplorerModal is_modal="True" />}
        {modal.name === "user_preferences" && <UserPreferencesModal />}
        {modal.name === "payments" && <PaymentModal />}
        {modal.name === "group_management" && <GroupManagementModal />}
      </section>
    </>
  );
}

export default Sidebar;
