// src/components/tools/ToolMaterialLocator.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { runMaterialSearch } from '../../api.js';
import './ToolMaterialLocator.css';

function ToolMaterialLocator({ tool }) {
    const token = useSelector((state) => state.user.access_token);

    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [outputCount, setOutputCount] = useState('5');

    const handleSearch = async () => {
        if (query.trim().length === 0) return;

        setIsLoading(true);
        setSubmitted(false);
        setError(null);

        try {
            const searchResults = await runMaterialSearch(
                query,
                outputCount,
                token
            );

            setResults(searchResults.materials || []);
            setSubmitted(true);
        } catch (err) {
            setError(err.message || 'Failed to run material search');
            console.error('Error running material search:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchClick = () => {
        const tooltip = document.getElementById('tml-tooltip');
        tooltip.style.display = 'block';
        setTimeout(() => {
            tooltip.style.display = 'none';
        }, 3000);
    };

    // Render the table dynamically based on the columns in the results
    const renderTable = () => {
        if (!results.length) return null;

        // Get all unique column names from the results
        const columns = [...new Set(
            results.flatMap(result => Object.keys(result))
        )];

        return (
            <table className="tool-materiallocator-table">
                <thead>
                    <tr>
                        {columns.map(column => (
                            <th key={column}>
                                {column.split('_').map(word =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, idx) => (
                        <tr key={idx}>
                            {columns.map(column => (
                                <td key={column}>
                                    {column === 'link' ? (
                                        <a href={result[column]}>View</a>
                                    ) : result[column]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="tool-materiallocator-container">
            <h1 className="tool-materiallocator-title">{tool.title}</h1>
            <p className="tool-materiallocator-description">{tool.description}</p>

            <div className="tool-materiallocator-query">
                <textarea
                    className="tool-materiallocator-textarea"
                    placeholder="Describe which materials you're looking for..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
            </div>

            <div className="tool-materiallocator-outputcount">
                <label htmlFor="outputCount">Number of outputs:</label>
                <select
                    id="outputCount"
                    value={outputCount}
                    onChange={(e) => setOutputCount(e.target.value)}
                >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                </select>
            </div>

            <div className="tml-tooltip-container">
                <button
                    className="tool-materiallocator-button"
                    onClick={handleSearchClick}
                    disabled={query.trim().length === 0 || isLoading}
                >
                    {isLoading ? (
                        <div className="tool-materiallocator-loading">
                            <div className="tool-materiallocator-spinner"></div>
                            <span>Searching...</span>
                        </div>
                    ) : (
                        'Search'
                    )}
                </button>
                <div id="tml-tooltip" className="tml-tooltip">
                    Material search is only available to enterprise customers. Please contact sales@labkick.ai
                </div>
            </div>

            {submitted && !isLoading && (
                <div className="tool-materiallocator-results">
                    <h3>Results</h3>
                    {error ? (
                        <div className="tool-materiallocator-error">{error}</div>
                    ) : (
                        <>
                            {results.length > 0 ? (
                                <>
                                    <div className="tool-materiallocator-success">
                                        Found {results.length} matching materials.
                                    </div>
                                    {renderTable()}
                                </>
                            ) : (
                                <div className="tool-materiallocator-noresults">
                                    No results found.
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default ToolMaterialLocator;
