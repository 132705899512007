import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import { updateModal, removeToolFile } from '../store/project.js';
import '../style/UploadToTool.css';

function UploadToTool() {
  const dispatch = useDispatch();
  const selectedFiles = useSelector((state) => state.project.tools_file_ids);

  const handleOpenFileExplorer = () => {
    dispatch(updateModal({ name: 'dna_archive_general', data: null }));
  };

  const handleRemoveFile = (fileId) => {
    dispatch(removeToolFile(fileId));
  };

  return (
    <div className="upload-to-tool">
      <button 
        className="tool-page-file-button"
        onClick={handleOpenFileExplorer}
      >
        Add Sequences
      </button>

      {selectedFiles.length > 0 && (
        <div className="selected-files">
          <h3>Selected Files:</h3>
          <div className="selected-files-list">
            {selectedFiles.map((file) => (
              <div key={file.id} className="selected-file">
                <span>{file.name}</span>
                <button
                  onClick={() => handleRemoveFile(file.id)}
                  className="remove-file-button"
                  aria-label={`Remove ${file.name}`}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadToTool; 