export const processProjectFiles = (project) => {
    const fileStructure = [
        {
            type: "folder",
            name: "Parts",
            id: "dna-folder",
            uuid: "dna-folder-uuid",
            children: []
        },
        // {
        //     type: "folder",
        //     name: "Resources",
        //     id: "resources-folder",
        //     uuid: "resources-folder-uuid",
        //     children: []
        // },
        {
            type: "folder",
            name: "Protocols",
            id: "outputs-folder",
            uuid: "outputs-folder-uuid",
            children: []
        },
        {
            type: "folder",
            name: "Constructs",
            id: "constructs-folder",
            uuid: "constructs-folder-uuid",
            children: []
        }
    ];
    let dnaFileCount = 0;
    // let resourcesFileCount = 0;
    let outputsFileCount = 0;
    let constructsFileCount = 0;

    // Ensure project.files is an object before processing
    const files = project.files || {};

    console.log("Files", files);

    Object.entries(files).forEach(([fileId, fileData]) => {
        const fileItem = {
            type: "file",
            name: fileData.file_name,
            uuid: fileId,
            size: fileData.size || 0,
            category: fileData.category,
            s3_address: fileData.s3_address,
            file_type: fileData.file_type
        };

        if (fileData.file_type === "DNA") {
            fileStructure[0].children.push(fileItem);
            dnaFileCount++;
        } else if (fileData.file_type === "Output") {
            fileStructure[1].children.push(fileItem);
            outputsFileCount++;
        } else if (fileData.file_type === "TargetConstruct") {
            fileStructure[2].children.push(fileItem);
            constructsFileCount++;
        } else {
            fileStructure.push(fileItem);
        }
    });

    fileStructure[0].dnaFileCount = dnaFileCount;
    fileStructure[0].partsFileCount = dnaFileCount;
    // fileStructure[1].resourcesFileCount = resourcesFileCount;
    fileStructure[1].outputsFileCount = outputsFileCount;
    fileStructure[1].protocolsFileCount = outputsFileCount;
    fileStructure[2].constructsFileCount = constructsFileCount;
    fileStructure[2].targetConstructFileCount = constructsFileCount;
    return fileStructure;
};

export const cleanProtocolMarkdown = (content) => {
    if (!content) return '';

    // Split content into lines for processing
    let lines = content.split('\n');
    let cleanedLines = [];
    let inTable = false;
    let tableRows = [];
    let foundFirstImage = false;

    // Process each line
    for (let i = 0; i < lines.length; i++) {
        let line = lines[i];

        // Handle images - keep only the first one with its base64 data
        if (line.includes('![') && !foundFirstImage) {
            const imageMatch = line.match(/!\[([^\]]*)\]\((data:image\/[^;]+;base64,[^)]+)\)/);
            if (imageMatch) {
                cleanedLines.push(`![${imageMatch[1]}](${imageMatch[2]})`);
                foundFirstImage = true;
            }
            continue;
        }

        // Skip additional images
        if (line.includes('![') && foundFirstImage) {
            continue;
        }

        // Handle input fields
        if (line.includes('<input')) {
            line = line.replace(/<input[^>]*value="([^"]*)"[^>]*>/g, '`$1`');
        }

        // Handle select elements
        if (line.includes('<select')) {
            const match = line.match(/selected>([^<]*)</);
            if (match) {
                line = line.replace(/<select[^>]*>.*?<\/select>/g, '`' + match[1].trim() + '`');
            } else {
                line = line.replace(/<select[^>]*>.*?<\/select>/g, '');
            }
        }

        // Handle tables
        if (line.includes('<table>')) {
            inTable = true;
            continue;
        }
        if (line.includes('</table>')) {
            // Process and add table
            if (tableRows.length > 0) {
                cleanedLines.push(processTable(tableRows));
                tableRows = [];
            }
            inTable = false;
            continue;
        }
        if (inTable) {
            if (line.trim() && !line.includes('</tr>') && !line.includes('<tr>')) {
                tableRows.push(line);
            }
            continue;
        }

        // Add non-table lines
        if (line.trim()) {
            cleanedLines.push(line);
        }
    }

    return cleanedLines.join('\n');
};

// Helper function to copy markdown content to clipboard
export const copyMarkdownToClipboard = async (content) => {
    try {
        const cleanedContent = cleanProtocolMarkdown(content);
        await navigator.clipboard.writeText(cleanedContent);
        return true;
    } catch (err) {
        console.error('Failed to copy markdown:', err);
        return false;
    }
};

// Helper function to process table rows into markdown format
const processTable = (tableRows) => {
    let markdownTable = [];
    
    // Process each row
    tableRows.forEach((row, index) => {
        // Clean row content
        let cleanRow = row
            .replace(/\|/g, '') // Remove existing pipes
            .trim()
            .split(/\s{2,}/)    // Split by 2 or more spaces
            .map(cell => {
                // Handle cells with <br> tags
                cell = cell.replace(/<br>/g, ', ');
                // Handle input fields in cells
                cell = cell.replace(/<input[^>]*value="([^"]*)"[^>]*>/g, '$1');
                return cell.trim();
            })
            .filter(cell => cell !== '') // Remove empty cells
            .join(' | ');

        // Only add the row if it contains actual content
        if (cleanRow && !cleanRow.match(/^[-\s|]+$/)) {
            markdownTable.push('| ' + cleanRow + ' |');

            // Add separator row after header, but only if this is the first row
            if (index === 0) {
                const columnCount = cleanRow.split('|').length;
                const separatorRow = '|' + ' --- |'.repeat(columnCount);
                markdownTable.push(separatorRow);
            }
        }
    });

    return markdownTable.join('\n');
};

