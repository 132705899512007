// src/components/tools/ToolDigestVisualizer.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeToolFile } from '../../store/project.js';
import UploadToTool from '../UploadToTool.js';
import Select from 'react-select';
import { enzyme_list } from '../../store/restriction_enzymes.js';
import './ToolDigestVisualizer.css';
import { runDigestVisualization } from '../../api.js';

const selectToolsFileIds = state => state.project.tools_file_ids;

const ladderOptions = [
    { value: 'NEB_1 kb Plus DNA Ladder', label: 'NEB 1 kb Plus DNA Ladder' },
    { value: 'NEB_1 kb DNA Ladder', label: 'NEB 1 kb DNA Ladder' },
    { value: 'NEB_100 bp DNA Ladder', label: 'NEB 100 bp DNA Ladder' },
    { value: 'NEB_50 bp DNA Ladder', label: 'NEB 50 bp DNA Ladder' },
    { value: 'NEB_Low Molecular Weight DNA Ladder', label: 'NEB Low Molecular Weight DNA Ladder' },
    { value: 'Thermo_GeneRuler 1 kb DNA Ladder', label: 'Thermo GeneRuler 1 kb DNA Ladder' },
    { value: 'Thermo_GeneRuler 1 kb Plus DNA Ladder', label: 'Thermo GeneRuler 1 kb Plus DNA Ladder' },
    { value: 'Thermo_GeneRuler 100 bp DNA Ladder', label: 'Thermo GeneRuler 100 bp DNA Ladder' },
    { value: 'Thermo_GeneRuler 100 bp Plus DNA Ladder', label: 'Thermo GeneRuler 100 bp Plus DNA Ladder' },
    { value: 'Thermo_GeneRuler 50 bp DNA Ladder', label: 'Thermo GeneRuler 50 bp DNA Ladder' }
];

// Convert enzyme list to options format for react-select
const enzymeOptions = enzyme_list.unique_enzymes.map(enzyme => ({
    value: enzyme,
    label: enzyme
}));

function ToolDigestVisualizer({ tool }) {
    const dispatch = useDispatch();
    const selectedFiles = useSelector(selectToolsFileIds);
    const token = useSelector((state) => state.user.access_token);

    const [showWarning, setShowWarning] = useState(false);
    const [submittedFiles, setSubmittedFiles] = useState(null);

    const [selectedEnzymes, setSelectedEnzymes] = useState([]);
    const [selectedLadder, setSelectedLadder] = useState(ladderOptions[0]);
    const [fragmentResults, setFragmentResults] = useState({});
    const [gelImage, setGelImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (selectedFiles.length > 10) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [selectedFiles]);

    const handleEnzymesChange = (selectedOptions) => {
        setSelectedEnzymes(selectedOptions || []);
    };

    const handleLadderChange = (selectedOption) => {
        setSelectedLadder(selectedOption);
    };

    const handleDigest = async () => {
        if (selectedFiles.length === 0 || selectedFiles.length > 10 || selectedEnzymes.length === 0) {
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const fileIds = selectedFiles.map(file => file.id);
            const result = await runDigestVisualization(
                fileIds,
                selectedLadder.value,
                selectedEnzymes.map(enzyme => enzyme.value),
                token
            );

            setFragmentResults(result.fragments || {});
            setGelImage(`data:image/png;base64,${result.gel_image}`);
            setSubmittedFiles(selectedFiles.map(file => file.name).join(', '));

            // Clear selected files after successful submission
            selectedFiles.forEach(file => {
                dispatch(removeToolFile(file.id));
            });
        } catch (err) {
            setError(err.message || 'Failed to run digest visualization');
            console.error('Error running digest:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="tool-digest-visualizer-container">
            <h1 className="tool-digest-title">{tool.title}</h1>
            <p className="tool-digest-description">{tool.description}</p>

            <div className="tool-digest-upload">
                <UploadToTool />
            </div>

            {showWarning && (
                <div className="tool-digest-warning">
                    Only 10 files can be processed at a time
                </div>
            )}

            <div className="tool-digest-inputs">
                <div className="dropdown-container">
                    <label htmlFor="enzymeSelect">
                        <strong>Restriction Enzymes:</strong>
                    </label>
                    <Select
                        id="enzymeSelect"
                        isMulti
                        options={enzymeOptions}
                        value={selectedEnzymes}
                        onChange={handleEnzymesChange}
                        className="protocol-dropdown"
                        classNamePrefix="protocol-dropdown"
                        placeholder="Select restriction enzymes"
                    />
                </div>

                <div className="dropdown-container">
                    <label htmlFor="ladderSelect">
                        <strong>DNA Ladder:</strong>
                    </label>
                    <Select
                        id="ladderSelect"
                        options={ladderOptions}
                        value={selectedLadder}
                        onChange={handleLadderChange}
                        className="protocol-dropdown"
                        classNamePrefix="protocol-dropdown"
                        placeholder="Select a DNA Ladder"
                    />
                </div>
            </div>

            <button
                className="tool-digest-button"
                onClick={handleDigest}
                disabled={selectedFiles.length === 0 || selectedFiles.length > 10 || selectedEnzymes.length === 0 || isLoading}
            >
                {isLoading ? 'Running Digestion...' : 'Run Digestion'}
            </button>

            {error && (
                <div className="tool-digest-error">
                    {error}
                </div>
            )}

            {submittedFiles && !error && (
                <div className="tool-digest-results">
                    <div className="tool-digest-success">
                        Analysis complete for: {submittedFiles}
                    </div>
                    <div className="tool-digest-fragments">
                        <h3>Fragment Lengths</h3>
                        <table className="fragments-table">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>Fragment Sizes (bp)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(fragmentResults).map(([fileName, fragments]) => (
                                    <tr key={fileName}>
                                        <td>{fileName}</td>
                                        <td>{fragments.join(', ')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="tool-digest-gel">
                        <h3>Gel Visualization</h3>
                        {gelImage && <img src={gelImage} alt="Digestion Gel" />}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ToolDigestVisualizer;
