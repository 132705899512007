// src/components/tools/ToolSequenceFinder.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UploadToTool from '../UploadToTool.js';
import { removeToolFile } from '../../store/project.js';
import { runSequenceSearch } from '../../api.js';
import './ToolSequenceFinder.css';

function ToolSequenceFinder({ tool }) {
    const selectedFiles = useSelector((state) => state.project.tools_file_ids);
    const token = useSelector((state) => state.user.access_token);
    const dispatch = useDispatch();

    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [outputCount, setOutputCount] = useState('5');
    const [submittedFiles, setSubmittedFiles] = useState(null);
    const [databases, setDatabases] = useState({
        addgene: true,
        personal: false,
        company: false
    });

    useEffect(() => {
        return () => {
            setResults([]);
            setSubmitted(false);
            // Clear all selected files when component unmounts
            selectedFiles.forEach(file => {
                dispatch(removeToolFile(file.id));
            });
        };
    }, [dispatch]);

    const handleDatabaseChange = (db) => {
        setDatabases((prev) => ({
            ...prev,
            [db]: !prev[db]
        }));
    };

    const handleSearch = async () => {
        if (query.trim().length === 0) return;

        setIsLoading(true);
        setSubmitted(false);
        setError(null);

        try {
            // Format file names for success message
            const fileNames = selectedFiles.map(file => file.name);
            const formattedFileNames = fileNames.length > 1
                ? `${fileNames.slice(0, -1).join(', ')} and ${fileNames.slice(-1)}`
                : fileNames.length === 1 ? fileNames[0] : '';

            const fileIds = selectedFiles.map(file => file.id);
            const searchResults = await runSequenceSearch(
                fileIds,
                query,
                databases,
                outputCount,
                token
            );

            // Clear selected files after processing
            selectedFiles.forEach(file => {
                dispatch(removeToolFile(file.id));
            });

            setResults(searchResults.sequences || []);
            setSubmitted(true);
            setSubmittedFiles(formattedFileNames);
        } catch (err) {
            setError(err.message || 'Failed to run sequence search');
            console.error('Error running sequence search:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Render the table dynamically based on the columns in the results
    const renderTable = () => {
        if (!results.length) return null;

        // Define the desired column order
        const columnOrder = ['link', 'name', 'purpose', 'reasoning', 'backbone', 'gene', 'score'];

        // Filter the column order to only include columns that exist in the results
        const columns = columnOrder.filter(col =>
            results.some(result => col in result)
        );

        return (
            <table className="tool-sequencefinder-table">
                <thead>
                    <tr>
                        {columns.map(column => (
                            <th key={column}>
                                {column.split('_').map(word =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                ).join(' ')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, idx) => (
                        <tr key={idx}>
                            {columns.map(column => (
                                <td key={column}>
                                    {column === 'link' ? (
                                        <a
                                            href={result[column]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View
                                        </a>
                                    ) : result[column]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="tool-sequencefinder-container">
            <h1 className="tool-sequencefinder-title">{tool.title}</h1>
            <p className="tool-sequencefinder-description">{tool.description}</p>

            <div className="tool-sequencefinder-upload">
                <UploadToTool />
            </div>

            <div className="tool-sequencefinder-query">
                <textarea
                    className="tool-sequencefinder-textarea"
                    placeholder="Describe what you're looking for..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
            </div>

            <div className="tool-sequencefinder-databases">
                <h3>Databases</h3>
                <div className="tool-sequencefinder-databases-content">
                    <div className="checkbox-group tsf-tooltip-container">
                        <input
                            type="checkbox"
                            id="addgene"
                            checked={databases.addgene}
                            onChange={() => handleDatabaseChange('addgene')}
                        />
                        <label
                            htmlFor="addgene"
                            onMouseEnter={() => {
                                const tooltip = document.getElementById('tsf-addgene-tooltip');
                                tooltip.style.display = 'block';
                            }}
                            onMouseLeave={() => {
                                const tooltip = document.getElementById('tsf-addgene-tooltip');
                                tooltip.style.display = 'none';
                            }}
                        >AddGene</label>
                        <div id="tsf-addgene-tooltip" className="tsf-tooltip">
                            This searches AddGene using LabKick's proprietary AI methods
                        </div>
                    </div>
                    <div className="checkbox-group tsf-tooltip-container">
                        <input
                            type="checkbox"
                            id="personal"
                            checked={databases.personal}
                            onChange={() => {
                                const tooltip = document.getElementById('tsf-personal-tooltip');
                                tooltip.style.display = 'block';
                                setTimeout(() => {
                                    tooltip.style.display = 'none';
                                }, 3000);
                            }}
                        />
                        <label
                            htmlFor="personal"
                            onMouseEnter={() => {
                                const tooltip = document.getElementById('tsf-personal-tooltip');
                                tooltip.style.display = 'block';
                            }}
                            onMouseLeave={() => {
                                const tooltip = document.getElementById('tsf-personal-tooltip');
                                tooltip.style.display = 'none';
                            }}
                        >Personal</label>
                        <div id="tsf-personal-tooltip" className="tsf-tooltip">
                            Searching personal sequences is only available to enterprise customers.
                            Please contact sales@labkick.ai
                        </div>
                    </div>
                    <div className="checkbox-group tsf-tooltip-container">
                        <input
                            type="checkbox"
                            id="company"
                            checked={databases.company}
                            onChange={() => {
                                const tooltip = document.getElementById('tsf-company-tooltip');
                                tooltip.style.display = 'block';
                                setTimeout(() => {
                                    tooltip.style.display = 'none';
                                }, 3000);
                            }}
                        />
                        <label
                            htmlFor="company"
                            onMouseEnter={() => {
                                const tooltip = document.getElementById('tsf-company-tooltip');
                                tooltip.style.display = 'block';
                            }}
                            onMouseLeave={() => {
                                const tooltip = document.getElementById('tsf-company-tooltip');
                                tooltip.style.display = 'none';
                            }}
                        >Company</label>
                        <div id="tsf-company-tooltip" className="tsf-tooltip">
                            Searching company sequences is only available to enterprise customers.
                            Please contact sales@labkick.ai
                        </div>
                    </div>
                </div>
            </div>

            <div className="tool-sequencefinder-outputcount">
                <label htmlFor="outputCount">Number of outputs:</label>
                <select
                    id="outputCount"
                    value={outputCount}
                    onChange={(e) => setOutputCount(e.target.value)}
                >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                </select>
            </div>

            <button
                className="tool-sequencefinder-button"
                onClick={handleSearch}
                disabled={query.trim().length === 0 || isLoading}
            >
                {isLoading ? (
                    <div className="tool-sequencefinder-loading">
                        <div className="tool-sequencefinder-spinner"></div>
                        <span>Searching...</span>
                    </div>
                ) : (
                    'Search'
                )}
            </button>

            {submitted && !isLoading && (
                <div className="tool-sequencefinder-results">
                    <h3>Results</h3>
                    {error ? (
                        <div className="tool-sequencefinder-error">{error}</div>
                    ) : (
                        <>
                            {results.length > 0 ? (
                                <>
                                    {submittedFiles && (
                                        <div className="tool-sequencefinder-success">
                                            {submittedFiles ? `Processed ${submittedFiles}. ` : ''}
                                            Found {results.length} matching sequences.
                                        </div>
                                    )}
                                    {renderTable()}
                                </>
                            ) : (
                                <div className="tool-sequencefinder-noresults">
                                    No results found.
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default ToolSequenceFinder;
