import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SeqViz } from "seqviz";
import "../style/Sequence.css";
import { downloadFiles } from "../api.js";
import { fetchArtifactData } from "../store/project.js";

function Sequence() {
  const artifact = useSelector((state) => state.project.artifact);
  const userFiles = useSelector((state) => state.user.files);
  const token = useSelector((state) => state.user.access_token);
  const [viewerType, setViewerType] = useState("circular");
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [transformationType, setTransformationType] = useState("reverse_complement");
  const [showTransformConfirm, setShowTransformConfirm] = useState(false);
  const [selectedCutGroups, setSelectedCutGroups] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedSequence(null);
  }, [artifact]);

  useEffect(() => {
    if (artifact?.file_id && userFiles[artifact.file_id]) {
      const fileTopology = userFiles[artifact.file_id].sequence_topology;
      setViewerType(fileTopology === 'circular' ? 'circular' : 'linear');
    }
  }, [artifact?.file_id, userFiles]);

  const sequenceNames = artifact ? Object.keys(artifact).filter(
    (key) => key !== "type" && key !== "file_id" && key !== "protocol_id" && key !== "digest_vendor" && !key.startsWith("table_input_")
  ) : [];

  const currentSequence = selectedSequence || sequenceNames[0];
  const sequenceData = artifact?.[currentSequence];

  const enzymeGroups = useMemo(() => {
    if (!sequenceData?.enzyme_counts) return {};

    const groups = {
      single: [],
      double: [],
      multiple: []
    };

    // Get enzyme counts for the current sequence
    const enzymeCounts = sequenceData.enzyme_counts[currentSequence] || sequenceData.enzyme_counts;

    // Process enzyme counts
    Object.entries(enzymeCounts).forEach(([cutCount, enzymes]) => {
      // Make sure enzymes is an array before trying to iterate
      if (!Array.isArray(enzymes)) return;

      const count = parseInt(cutCount);
      enzymes.forEach(enzyme => {
        if (enzyme.fcut === '0' && enzyme.rcut === '0') return;

        const enzymeData = {
          name: enzyme.name,
          rseq: enzyme.rseq,
          fcut: parseInt(enzyme.fcut),
          rcut: parseInt(enzyme.rcut)
        };

        if (count === 1) {
          groups.single.push(enzymeData);
        } else if (count === 2) {
          groups.double.push(enzymeData);
        } else if (count > 2) {
          groups.multiple.push(enzymeData);
        }
      });
    });

    return groups;
  }, [sequenceData, currentSequence]);

  const selectedEnzymes = useMemo(() => {
    if (!enzymeGroups) return sequenceData?.cutting_enzymes || [];

    // Get the filtered enzymes from groups
    const filteredEnzymes = selectedCutGroups.flatMap(group => enzymeGroups[group] || []);

    // If there are cutting_enzymes, combine them with filtered enzymes
    if (sequenceData?.cutting_enzymes) {
      // Handle both array and object cases for cutting_enzymes
      const cuttingEnzymes = Array.isArray(sequenceData.cutting_enzymes)
        ? sequenceData.cutting_enzymes
        : sequenceData.cutting_enzymes[currentSequence] || [];

      return [...filteredEnzymes, ...cuttingEnzymes];
    }

    return filteredEnzymes;
  }, [enzymeGroups, selectedCutGroups, sequenceData?.cutting_enzymes, currentSequence]);

  if (!artifact || !sequenceData) {
    return null;
  }

  const handleDownload = () => {
    console.log("Download - artifact:", artifact);
    if (artifact && artifact.file_id) {
      downloadFiles(artifact.file_id, "file", token);
    } else {
      console.error("No file_id available for the current artifact");
    }
  };

  const handleTransform = () => {
    setShowTransformConfirm(true);
  };

  const handleConfirmTransform = () => {
    if (artifact && artifact.file_id) {
      dispatch(fetchArtifactData({
        fileId: artifact.file_id,
        transformationType: transformationType,
        sequenceName: currentSequence
      }));
    }
    setShowTransformConfirm(false);
  };

  const handleSequenceChange = (e) => {
    setSelectedSequence(e.target.value);
  };

  const renderEnzymeControls = () => (
    <div className="enzyme-controls">
      <label className="enzyme-controls-label">Cut Site Groups</label>
      <div className="enzyme-checkbox-group">
        <label>
          <input
            type="checkbox"
            checked={selectedCutGroups.includes('single')}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCutGroups([...selectedCutGroups, 'single']);
              } else {
                setSelectedCutGroups(selectedCutGroups.filter(g => g !== 'single'));
              }
            }}
          />
          Single cutters ({enzymeGroups.single?.length || 0})
        </label>

        <label>
          <input
            type="checkbox"
            checked={selectedCutGroups.includes('double')}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCutGroups([...selectedCutGroups, 'double']);
              } else {
                setSelectedCutGroups(selectedCutGroups.filter(g => g !== 'double'));
              }
            }}
          />
          Double cutters ({enzymeGroups.double?.length || 0})
        </label>

        <label>
          <input
            type="checkbox"
            checked={selectedCutGroups.includes('multiple')}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCutGroups([...selectedCutGroups, 'multiple']);
              } else {
                setSelectedCutGroups(selectedCutGroups.filter(g => g !== 'multiple'));
              }
            }}
          />
          3+ cutters ({enzymeGroups.multiple?.length || 0})
        </label>
      </div>
    </div>
  );

  return (
    <div className="sequence-content">
      <div className="viewer-controls">
        <div className="dropdowns-container">
          <div className="viewer-type-container">
            <label className="viewer-type-label" htmlFor="viewer-type-dropdown">
              Viewer
            </label>
            <select
              id="viewer-type-dropdown"
              className="viewer-type-dropdown"
              value={viewerType}
              onChange={(e) => setViewerType(e.target.value)}
            >
              <option value="linear">Linear</option>
              <option value="circular">Circular</option>
              <option value="both">Both (Circular Left)</option>
              <option value="both_flip">Both (Circular Right)</option>
            </select>
          </div>

          {sequenceNames.length > 1 && (
            <div className="sequence-select-container">
              <label className="sequence-select-label" htmlFor="sequence-select-dropdown">
                Sequence
              </label>
              <select
                id="sequence-select-dropdown"
                className="sequence-select-dropdown"
                value={currentSequence}
                onChange={handleSequenceChange}
              >
                {sequenceNames.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        {renderEnzymeControls()}
        <div className="sequence-controls-right">
          <div className="sequence-transform-container">
            <label className="sequence-transform-label" htmlFor="sequence-transform-dropdown">
              Transform
            </label>
            <select
              id="sequence-transform-dropdown"
              className="sequence-transform-dropdown"
              value={transformationType}
              onChange={(e) => setTransformationType(e.target.value)}
            >
              <option value="reverse_complement">Reverse Complement</option>
              <option value="reverse">Reverse</option>
              <option value="complement">Complement</option>
            </select>
          </div>

          <div className="sequence-button-group">
            <button
              className="sequence-transform-button"
              onClick={handleTransform}
            >
              Overwrite File
            </button>
            <button className="sequence-protocol-download-button" title="Download" onClick={handleDownload}>
              <img src="/images/download.svg" alt="Download" className="protocol-download-icon" />
            </button>
          </div>
        </div>
      </div>
      <SeqViz
        name={sequenceData.name || currentSequence}
        seq={sequenceData.seq}
        annotations={sequenceData.annotations}
        enzymes={selectedEnzymes}
        highlights={sequenceData.highlights}
        viewer={viewerType}
      />

      {showTransformConfirm && (
        <div className="sequence-popup-overlay">
          <div className="sequence-popup-dialog">
            <div className="sequence-popup-header">
              <h2>Confirm Transform</h2>
              <button
                className="sequence-popup-close"
                onClick={() => setShowTransformConfirm(false)}
              >
                ×
              </button>
            </div>
            <div className="sequence-popup-content">
              <p>Are you sure? This will save over the underlying file.</p>
              {(transformationType === "reverse" || transformationType === "complement") && (
                <p>Warning: This operation will remove all annotations.</p>
              )}
              {sequenceNames.length > 1 && (
                <p>Note: This will only transform the currently selected sequence "{currentSequence}". All other sequences in this multi-file will remain unchanged.</p>
              )}
            </div>
            <div className="sequence-popup-buttons">
              <button
                onClick={() => setShowTransformConfirm(false)}
                className="sequence-cancel-button"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmTransform}
                className="sequence-confirm-button"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sequence;
