import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import FileExplorerModal from "./FileExplorerModal.js";
import "../style/LIMS.css";
import { useSelector, useDispatch } from "react-redux";
import FileUploadModal from "./FileUploadModal.js";
import { updateAccessToken, fetchUser, setUserStatus, setInitialized } from "../store/user.js";
import { fetchProjects } from "../store/project.js";

function LIMS() {
  const { artifact, currentProjectId, modal, projectList } = useSelector((state) => state.project);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const isInitialized = useSelector((state) => state.user.isInitialized);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeLIMS = async () => {
      if (!isInitialized) {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(updateAccessToken(accessToken));
          const result = await dispatch(fetchUser({ token: accessToken, user_auth0: user }));

          if (result?.payload?.data?.data?.user_id) {
            dispatch(fetchProjects(result.payload.data.data.user_id));
            dispatch(setInitialized(true));
          }
        } catch (error) {
          console.error("Error initializing LIMS:", error);
        }
      }
    };

    if (isAuthenticated && !isInitialized) {
      initializeLIMS();
    } else if (!isAuthenticated && !isLoading) {
      dispatch(setUserStatus("unauthenticated"));
      navigate('/');
    }
  }, [isAuthenticated, isLoading, isInitialized]);

  if (isLoading) {
    return (
      <div className="initial-loading-overlay" role="alert" aria-busy="true">
        <div className="loading-spinner" aria-label="Loading content"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="lims-wrapper">
      <FileExplorerModal is_modal={false} />
      {modal.name === "upload_general" && <FileUploadModal />}
    </div>
  );
}

export default LIMS; 